<template>
  <v-app id="login">
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>เข้าสู่ระบบ</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="login">
                  <v-select
                    :items="items"
                    label="สาขา"
                    ref="Org"
                    prepend-icon="fa fa-home"
                    autofocus
                    v-model="OrganizationId"
                  ></v-select>
                  <v-text-field
                    label="ผู้ใช้"
                    prepend-icon="fa fa-user"
                    type="text"
                    v-model="UserAccount"
                    ref="UserAccount"
                  />

                  <v-text-field
                    label="รหัสผ่าน"
                    prepend-icon="fa fa-lock"
                    type="password"
                    v-model="UserPassword"
                    ref="UserPassword"
                  />

                  <v-btn
                    ref="btnLogin"
                    :loading="loading"
                    color="primary"
                    type="submit"
                    width="100%"
                  >ล๊อกอิน</v-btn>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      UserAccount: "",
      UserPassword: "",
      loading: false,
      items: [],
      OrganizationId: ""
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.org();
    });
  },
  methods: {
    org() {
      axios.defaults.headers.common["Authorization"] = "";
      axios.get("/Api/V1/Select/SelectAll/Organization").then(res => {
        if (res.status == 200) {
          this.items = res.data;
          this.OrganizationId = res.data[0].value;
        }
      });
    },
    login() {
      if (this.OrganizationId == "") {
        this.$refs.Org.focus();
        return;
      }
      if (this.UserAccount == "") {
        this.$refs.UserAccount.focus();
        return;
      }
      if (this.UserAccount == "") {
        this.$refs.UserAccount.focus();
        return;
      }
      if (this.UserPassword == "") {
        this.$refs.UserPassword.focus();
        return;
      }
      this.loading = true;

      axios
        .post("/Api/V1/Select/Login/User", {
          UserAccount: this.UserAccount,
          UserPassword: this.UserPassword,
          OrganizationId: this.OrganizationId
        })
        .then(res => {
          if (res.status == 200) {
            window.localStorage.removeItem("token");
            window.localStorage.setItem("token", res.data.token);
            axios.defaults.headers.common["Authorization"] = res.data.token;
            this.$router.push("/Billsale");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>